<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Credit/Debit Leaves</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{name: 'employees'}">Employees</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{name: 'employee-details',params: {id:$route.params.emp_id}}">{{creditLeaves.name}} [IES-{{$route.params.emp_id}}]</router-link>
                </li>
                <li class="breadcrumb-item active"  v-if="creditLeaves.name" aria-current="page">Credit Debit Leaves</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form >
                    <div class="form-group row">
                        <label class="col-md-3">
                            Employee:
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            {{creditLeaves.name}} [IES-{{$route.params.emp_id}}]
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3">
                            Leave Type:
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-form-select
                                v-model="creditLeaves.leave_type"
                                :options="leaveTypeOptions"
                                @change="getRemainingLeaves"
                            ></b-form-select> <br>
                            <div class="row p-2">
                                <div class="col-6">
                                    <span class="">Remaining Leaves: {{creditLeaves.remaining_leaves}}</span> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Type
                        <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <span class="mx-4">
                                <input type="radio" v-model="creditLeaves.type" value="+" class="form-check-input" />
                                <label class="form-check-label">Credit</label>
                            </span>
                            <span class="mx-4">
                                <input type="radio" v-model="creditLeaves.type" value="-" class="form-check-input"/>
                                <label class="form-check-label">Debit</label>
                            </span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-3">
                            Number of Leaves
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <input
                                type="number"
                                class="form-control"
                                id="numberOfLeaves"
                                aria-describedby="numberOfLeavesHelpInline"
                                v-model="creditLeaves.number_of_leaves"
                                autocomplete="off"
                            />
                        </div>
                    </div>
                  
                    <div class="form-group row">
                        <label class="col-md-3">
                            Reason
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <textarea
                                class="form-control"
                                aria-describedby="reasonHelpInline"
                                v-model="creditLeaves.reason"
                                autocomplete="off"
                                rows="3" 
                            ></textarea>
                        </div>
                    </div>
                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="info()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-secondary ma-10px"
                                @click="$router.go(-1)"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <b-modal
            :id="infoModal.id"
            :title="infoModal.title"
            centered
            size="md"
            no-close-on-esc
            no-close-on-backdrop
            @hide="resetInfoModal"
        >
            <p v-html="infoModal.message" class="float-left"></p>
            <div slot="modal-footer" class="w-100" >
                <b-button class="mt-3 float-right" @click="resetInfoModal">No</b-button>
                <b-button
                    class="mt-3 float-right btn btn-primary"
                    variant="danger"
                    style="margin-right:10px;"
                    @click="creditDebitLeaves()"
                >Yes</b-button>                
            </div>

        </b-modal>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            access: false,
            creditLeaves: {
                name: '',
                type: '',
                number_of_leaves: '',
                reason:'',
                emp_id:'',
                remaining_leaves:'',
                leave_type:'',
            },
            leaveTypeOptions: [],
            infoModal: {
                id: "modal-sm",
                title: "",
                content: "",
                message:'',
            },
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_UPDATE_EMPLOYEE");
        this.creditLeaves.emp_id= this.$route.params.emp_id;
        this.getEmployee();
        this.leaveType();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        info() {
            if(this.creditLeaves.number_of_leaves && this.creditLeaves.type){
                let type = (this.creditLeaves.type == '+')?'Credit':'Debit';
                this.api_error ="";
                this.infoModal.title = `Submit Confirmation`;
                this.infoModal.message = 'Are you sure you want to '+type+' '+ this.creditLeaves.number_of_leaves +' Leaves to ' +this.creditLeaves.name+ ' [IES-'+this.$route.params.emp_id+']?';
                this.$root.$emit("bv::show::modal", this.infoModal.id);
            } else if (!this.creditLeaves.type){
                this.api_error ="Type is required";
                this.showAlert();
            } else {
                this.api_error ="Number of leaves is required";
                this.showAlert();
            }
        },

        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },

        leaveType() {
            let leaveTypes = this.$store.getters.getAppSetting("payroll", "leave_types");
            leaveTypes.forEach(type => {
                this.leaveTypeOptions.push({
                    value: type,
                    text: type
                });
            });
            this.creditLeaves.leave_type = this.leaveTypeOptions[0].value;
            this.getRemainingLeaves(this.leaveTypeOptions[0].value);
        },
        getEmployee: function() {
            this.page_loader= true;
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                id: this.$route.params.emp_id
            };
            this.axios
                .get(this.$api.get_employees, query)
                .then(response => {
                    this.creditLeaves.name = response.data.data.full_name;
                    this.updateProgressBar(true);
                    this.page_loader = false;
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.page_loader = false;
                    this.showAlert();
                });
        },

        getRemainingLeaves: function(event) {
            this.page_loader= true;
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                emp_id: this.$route.params.emp_id,
                leave_type: event,
            };
            this.axios
                .get(this.$api.get_remaining_leaves, query)
                .then(response => {
                    this.creditLeaves.remaining_leaves = (response.data.data !== null) ? response.data.data.balance : 0;
                    this.updateProgressBar(true);
                    this.page_loader = false;
                })
                .catch(err => {
                    console.log(err);
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.page_loader = false;
                    this.showAlert();
                });
        },
        creditDebitLeaves: function() {
            this.startProgressBar();
            this.resetInfoModal();
            const query = this.getAuthHeaders();
            query["params"] = {};
            this.axios.post(this.$api.credit_debit_leaves, this.creditLeaves, query)
                .then(response => {
                    if (response.data.error) {
                        this.api_error = response.data.message;
                        this.showAlert();
                        this.updateProgressBar(true);
                        return;
                    }
                    this.$router.push({ name: "employees" });
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>
